import axios from "axios";
import { getToken } from "@/utils/cookie";

import {
  Notification
} from 'element-ui'
function getContentType(config) {
  var type = config.contentType || 'json'
  var charset = config.encoding || 'UTF-8'
  if (type === 'json' && (config.url.indexOf("nvc/public") !== -1 || config.url.includes('scan/wx/configdata'))) {
    return 'application/x-www-form-urlencoded;charset=' + charset
  } else if (type === 'json') {
    return 'application/json;charset=' + charset
  } else if (type === 'form') {
    return 'application/x-www-form-urlencoded;charset=' + charset
  } else if (type === 'file') {
    return 'multipart/form-data;charset=' + charset
  } else if (type === 'text') {
    return 'text/plain;charset=' + charset
  } else if (type === 'html') {
    return 'text/html;charset=' + charset
  } else {
    throw new Error('unsupported content type : ' + type)
  }
}

// 创建axios实例
const service = axios.create({
  // baseURL,
  // headers: {
  //   "Content-Type": "application/json",
  // },
  timeout: 20000,
});

const errHandle = (code, message) => {
  switch (code) {
    case 0:
      break;
    case 401:
      // store.dispatch('logout').then(() => {
      //   location.reload(); // 为了重新实例化 vue-router 对象,避免bug
      // });
      break;
    case 403:
      // 没有权限
      Notification.error({
        title: '错误',
        message: message
      });
      break;
    default:
      console.log(message);
      Notification.error({
        title: '错误',
        message: message
      });
  }
};


// request 拦截器
service.interceptors.request.use(
  (config) => {

    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = getContentType(config);
    }

    const token = getToken();
    if (token) {
      config.headers.token = token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }



    // 兼容params和data
    const useParams = ["get", "delete"].includes(config.method);
    config[useParams ? "params" : "data"] = config.data || config.params || {};
    if (config.method === "get") {
      config.params.t = +new Date();
    }
    const hrToken = window.sessionStorage.hrToken;
    if (hrToken) {
      config.headers.auth_token = hrToken;

      if (!config.params) {
        config.params = {}
      }

      config.params.auth_token = hrToken;
    }
    return config;
  },
  (error) =>
    Promise.reject({
      type: "clientReq",
      error,
    })
);

// response 拦截器
service.interceptors.response.use(
  // response => response,
  (response) => {
    const { message, code } = response.data;

    if (code == 200 || code === 201 || code === 1||code === 0) return response.data;
    if (Object.prototype.toString.call(response.data) === "[object Blob]")
      return response.data;
    errHandle(code, message);
    return Promise.reject({
      type: "client",
      code,
      message,
      data: response.data.data,
    });
  },

  (error) => {
    const { message, code } = error.response.data;
    errHandle(code, message);
    return Promise.reject({
      type: "server",
      code,
      message,
    });
  }
);

export default service;
// export { baseURL };
